
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import getUTC from "@/function/getUTC";

import { LocationQueryRaw } from "vue-router";
import GetLogRequest from "@/dataSource/api/logger-micro-service/services/models/request/log-getter-service/GetLogRequest";
import LogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/LogsItemResponse";
import EndpointLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetLogsResponse";
import GetActiveLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetActiveLogsResponse";
import notification from "@/plugins/notification/plugin.notification";

@Options({
  name: "content-logs-view-new",
  components: {
    TableView,
  },
})
export default class ContentLogsViewNew extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;
  @Prop({ default: false }) isActive: boolean;

  innerFilter: GetLogRequest;
  items: LogsItemResponse[] = [];
  page: PageRequest = { Take: 20, Skip: 0 };
  count = 0;
  noPagination = false;
  isLoading = false;

  error = {
    isSuccess: true,
    errorMessage: "",
  };

  created() {
    if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
      this.page.Skip = this.filter.SkipPage;
    }
  }

  onFilterChange() {
    this.page.Skip = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  mounted() {
    // if (this.$route.name == "content-logs-view") {
    this.refresh();
    // }
  }

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }

  // методы логов
  async refresh(filter: any = null) {
    if (!this.filter) {
      return;
    }
    if (filter) {
      this.filter.LogType = this.filter?.LogType ? filter.LogType : null;
      this.filter.LogLevel = filter.LogLevel;
      this.filter.Url = filter.Url;
      this.filter.DateFrom = filter.DateFrom;
      this.filter.DateTo = filter.DateTo;
      this.filter.Ip = filter.Ip;
      this.filter.UserId = filter.UserId;
      this.filter.SessionToken = filter.SessionToken;
      this.filter.ProjectLogGroupId = filter.ProjectLogGroupId;

      this.filter.ConnectionId = filter.ConnectionId;
      this.filter.RequestId = filter.RequestId;
      this.filter.OnlyActiveRequests = filter.OnlyActiveRequests;
      this.filter.LoggerName = filter.LoggerName;
      this.filter.ShortMessage = filter.ShortMessage;
    }

    // this.page.Skip = this.filter.SkipPage;
    let response: GetLogsResponse | GetActiveLogsResponse = null;
    this.isLoading = true;

    this.innerFilter = {
      LogType: this.filter.LogType,
      LogLevel: this.filter.LogLevel,
      Url: this.filter.Url,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: { Skip: this.page.Skip, Take: 20 },
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
      OnlyActiveRequests: this.filter.OnlyActiveRequests,
      LoggerName: this.filter.LoggerName,
      ShortMessage: this.filter.ShortMessage,
    };

    let tmpFilter = getUTC(this.innerFilter);
    console.log(31, this.filter.DateFrom);
    try {
      if (this.isActive) {
        response = await this.$api.LogGetterService.getActiveLogsAsync(tmpFilter);
      } else {
        response = await this.$api.LogGetterService.getLogsAsync(tmpFilter);
      }
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
    this.count = response.Page.Count;
    this.page.Take = response.Page.Size;
    this.page.Skip = response.Page.Size * (response.Page.Current - 1);

    this.items = (response.Items as any).map((item) => ({
      ProjectLogGroupId: item.ProjectLogGroupId,
      LogType: item.LogType,
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      Url: item.Url,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      ProjectName: item.ProjectName,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
      LoggerName: item.LoggerName,
      ShortMessage: item.ShortMessage,
      ServerName: item.ServerName,
      InstanceName: item.InstanceName,
    }));

    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage = this.page.Skip / this.page.Take + 1;
    }
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }

    this.page.Skip = skip;
    this.updateQuery();

    this.refresh();
  }

  updateQuery() {
    this.$emit("updateQuery", this.page);
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    let el = this.items.find((item) => item.RequestId == id);
    localStorage.setItem("contentLogsRequestId", el.RequestId);
    this.$router.push({ name: "logs-view-detail" });
  }
}
